<template>
    <r-e-dialog title="新建分类" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" width="500px" top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="资产编码" prop="">
                    <el-input v-model="formPublish.lastWater" style="width: 300px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="分类名称" prop="">
                    <el-input v-model="formPublish.lastWater" style="width: 300px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="上级分类" prop="">
                    <el-select v-model="formPublish.lastWater"  style="width: 300px;" placeholder="请选择">
                        <el-option label="全部" :value="0"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="资产折旧率" prop="">
                    <el-input v-model="formPublish.lastWater" style="width: 300px;"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    export default {
        name: "dialog-add-classification",
        data() {
            return {
                dialogVisible: false,
                formPublish: {},
                rules: {},
                imgList: []
            }
        },
        components: {},
        methods: {
            async openDialog() {
                let that = this;
                that.dialogVisible = true;
            },
            clickSubmit() {
                let that = this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        console.log(valid)
                    }
                })
            },
            clickCancel() {
                this.$emit("handleSearch", false);
                this.dialogVisible = false;
            },
        },
    }
</script>

<style scoped>

</style>